import React, { useState } from "react"
import ImageCollection from "../../components/ImageCollection"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import BildHeader from "../../images/services/sehtest/header.jpg"
import BildLinks from "../../images/services/sehtest/links.jpg"
import BildRechtsOben from "../../images/services/sehtest/rechtsoben.jpg"
import BildRechtsUnten from "../../images/services/sehtest/rechtsunten.jpg"
import SubNavigation from "../../components/SubNavServices"
import Termin from "../../components/Termin"

const Sehtest: React.FC = () => {
  const siteTitle = "Sehtest – Dienstleistungen"
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)

  return (
    <Layout title={siteTitle}>
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Sehtest"
      />
      <section className="wrapper greyColor content">
        <h1>Sehtest</h1>
        <h2 className="contentSubtitle">SCHWARZ Optik – Seit 1992 in Heiden</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageColumn">
                <div className="imageRow">
                  <img src={BildLinks} />
                </div>
              </div>
              <div className="imageColumn">
                <div className="imageRow">
                  <img src={BildRechtsOben} />
                </div>
                <div className="imageRow">
                  <img src={BildRechtsUnten} />
                </div>
              </div>
            </div>
            <p className="imageCaption">
              Die Sehleistungs- und Brillenglasabstimmung im rollstuhlgängigen
              Untersuchungsraum erfolgt mit modernsten Geräten der Optometrie.
            </p>
          </div>
          <div className="contentColumn">
            <p>Ein Sehtest bei SCHWARZ Optik umfasst:</p>
            <ul>
              <li>die eingehende Prüfung des beidäugigen Sehens</li>
              <li>die Inspektion des vorderen und hinteren Augenabschnittes</li>
              <li>
                eine Augendruckmessung
                <br />
                <br />
              </li>
            </ul>
            <h2>
              Sehstörungen als häufige Ursache von chronischen Beschwerden
            </h2>
            <p>
              Häufige Kopfschmerzen oder Konzentrationsproblemen haben ihre
              Ursachen oft bei kleinen, nicht sichtbaren Abweichungen beider
              Augen, die vom vegetativen Nervensystem kompensiert werden. Ein
              räumlich beschwerdefreies Sehen ist aber nur möglich, wenn sich
              die Bilder beider Augen im Sehzentrum des Gehirns zu einem
              gemeinsamen Bild verschmelzen lassen.
            </p>
            <h2>Ganz wichtig, die Früherkennung</h2>
            <p>
              Leichte Sehstörungen bei Kindern werden durch die Erwachsenen oft
              viel zu spät oder erst im Zusammenhang mit schulischen
              Lernstörungen erkannt. Schnell-Sehtests bringen solche
              spezifischen Abweichungen nicht an den Tag. Bei SCHWARZ Optik
              können Sie mögliche Abweichungen messen lassen und mit Visual
              Training oft zusammen mit Brillengläsern optimieren. Die Messung
              des Sehgleichgewichtes gehört bei uns zum Standard einer
              umfassenden Augenprüfung.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Sehtest
